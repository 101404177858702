import {
    containsV1inPath,
    getQueryParamsAndQueryString,
} from '@thoughtspot/env-flags';
import { useTranslation } from '@thoughtspot/i18n';
import { jsonConstants } from '@thoughtspot/json-constants';
import {
    Icon,
    IconColor,
    IconSize,
} from '@thoughtspot/radiant-react/widgets/icon';
import { Horizontal, View } from '@thoughtspot/radiant-react/widgets/layout';
import { RoutedLink } from '@thoughtspot/radiant-react/widgets/link';
import cx from 'classnames';
import React from 'react';
import { useMatches } from 'react-router';
import { useAppContext } from '/@contexts/global-app-context';
import { clearUndoStackSubject } from '/@services/answer/answer.util';
import { removeOverrideOrgId } from '/@services/system/nav-service/nav-service';
import { clearSageSearchSubject } from '/@utils/sage.utils';
import { ROUTE_IDS } from '../../../routing/routes';
import { ClassicNavItem } from './classic-nav.container';
import styles from './classic-nav.module.scss';

export enum ClassicNavItemsTestIDs {
    SearchDataBtn = 'search-data-btn',
}

export interface ClassicNavItemProps {
    applicationList: ClassicNavItem[];
}

export const ClassicNavItems: React.FC<ClassicNavItemProps> = ({
    applicationList,
}) => {
    const { t } = useTranslation();
    const { sessionService, navService } = useAppContext();
    const matches = useMatches();

    const handleRedirectToV1 = (application: ClassicNavItem) => {
        const { queryString } = getQueryParamsAndQueryString();
        let queryStringWithoutOrgQueryParam = queryString;
        if (
            application.homePath.includes('admin') &&
            sessionService.isOrgUiEnabled() &&
            sessionService.isOrgPerUrlEnabled()
        ) {
            queryStringWithoutOrgQueryParam = removeOverrideOrgId(queryString);
        }
        if (sessionService.isReactshellEnabled() && containsV1inPath()) {
            // eslint-disable-next-line security/detect-non-literal-fs-filename
            window.open(
                `/${queryStringWithoutOrgQueryParam}#${application.homePath}`,
                '_self',
            );
            return;
        }
        // eslint-disable-next-line security/detect-non-literal-fs-filename
        window.open(
            `${window.location.pathname}${queryStringWithoutOrgQueryParam}#${application.homePath}`,
            '_self',
        );
    };

    return (
        <Horizontal vAlignContent="center" className={styles.navItemsContainer}>
            {applicationList.map(application => {
                return (
                    application.shouldShow() && (
                        <RoutedLink
                            key={application.appName}
                            href={`/#${application.homePath}`}
                            data-testid={`classic-nav-item-${application.appName}`}
                            className={cx(styles.navItem, {
                                [styles.selectedNavItem]: application.isActive,
                            })}
                            onClick={() => {
                                if (application.shouldRedirectToV1) {
                                    handleRedirectToV1(application);
                                } else {
                                    application.clickHandler();
                                }
                            }}
                        >
                            {application.titleIconId ? (
                                <Horizontal spacing="a">
                                    <View
                                        vAlignContent="center"
                                        hAlignContent="center"
                                        grow={0}
                                        shrink={0}
                                    >
                                        {`${application.appName} `}
                                    </View>

                                    <View
                                        hAlignContent="center"
                                        grow={0}
                                        shrink={0}
                                    >
                                        <Icon
                                            data-testid={`application-switcher-icon-${application.titleIconId}`}
                                            id={application.titleIconId}
                                            size={IconSize.XSMALL}
                                            color={IconColor.WHITE}
                                        />
                                    </View>
                                </Horizontal>
                            ) : (
                                application.appName
                            )}
                        </RoutedLink>
                    )
                );
            })}
            {sessionService.getBooleanSessionFlag(
                jsonConstants.SEARCH_DATA_IN_NAV,
            ) && (
                <RoutedLink
                    href="#/answer"
                    className={styles.navSearchButton}
                    onClick={() => {
                        if (
                            matches.some(
                                match =>
                                    match.id.toLowerCase() ===
                                    ROUTE_IDS.Answer.toLowerCase(),
                            )
                        ) {
                            clearSageSearchSubject.next();
                            clearUndoStackSubject.next();
                        } else navService.goToAnswer();
                    }}
                    data-testid={ClassicNavItemsTestIDs.SearchDataBtn}
                    noUderline
                >
                    {t('primaryNavigation.SEARCH_DATA')}
                </RoutedLink>
            )}
        </Horizontal>
    );
};
