import { blinkConstants } from '@thoughtspot/blink-constants';
import { NavService, PrivilegedRouteObject } from '@thoughtspot/blink-context';
import { UserAction, userActionTypeEnum } from '@thoughtspot/metrics';
import {
    getCurrentOrgId,
    isOrgPerUrlEnabled,
    isOrgUiEnabled,
} from '@thoughtspot/session-service';
import {
    getIdpDomain,
    isDeepLinkingDisabled,
} from '@thoughtspot/system-config-service';
import _ from 'lodash';
import { useState } from 'react';
import {
    matchPath,
    NavigateFunction,
    NavigateOptions,
    To,
    useMatches,
} from 'react-router';
import {
    generatePath,
    unstable_usePrompt as usePrompt,
    useNavigate as useRouterNavigate,
    useSearchParams,
} from 'react-router-dom';
import { useAppContext } from '/@contexts/global-app-context';
import {
    isEmbeddedApp,
    isLiveboardEmbed,
    isSageEmbed,
} from '/@utils/embed.util';
import { markEvent } from '/@utils/pinboard-perf.util';
import { CORE_ROUTES } from '../../../routing/core-routes';
import { ApplicationList, Applications, ROUTES } from '../../../routing/routes';
import {
    FLAGS,
    flags,
    getQueryParamsAndQueryString,
} from '../config-service/flags-service';

export interface QueryParams {
    name: string;
    value: string | boolean;
}

const ABSOLUTE_URL_PATTERN = '{protocol}//{host}/{urlParams}#{relativePath}';

export function addClientQueryParamsToPath(
    path: string,
    flags: Array<QueryParams>,
) {
    if (!flags) {
        return path;
    }
    let newPath = path;
    newPath = `${newPath}?`;
    flags.forEach((flag, index) => {
        if (index !== 0) {
            newPath = `${newPath}&`;
        }
        newPath = `${newPath}${flag.name}=${flag.value}`;
    });
    return newPath;
}

function getUrlHref(): string {
    return window.location.href;
}

export const getUrlHash = () => {
    return window.location.hash;
};

function getEmbedRoute(route: string, isEmbedded: boolean): string {
    return isEmbedded ? `Embed_${route}` : route;
}

/*
 * isPlatformDeeplinkSupported checks whether platform is Android or IOS
 */
function isPlatformDeeplinkSupported() {
    const toMatch = [/Android/i, /iPhone/i, /iPad/i, /iPod/i];
    /* Added platform and maxTouchPoints check because starting iPadOs userAgent
     * that comes in safari doesn't mention that it is an iPad as Apple wants iPad to request desktop site by default
     */
    return (
        (navigator.platform === 'MacIntel' &&
            navigator.maxTouchPoints &&
            navigator.maxTouchPoints > 1) ||
        toMatch.some(toMatchItem => navigator.userAgent.match(toMatchItem))
    );
}

/*
 * doesUserWantBrowserExperience tell whether user has pressed on Continue in browser button
 */
function doesUserWantBrowserExperience() {
    // sessionStorage.getItem only return string
    return (
        sessionStorage.getItem(
            blinkConstants.deeplink.userWishesBrowserExperience,
        ) === 'true'
    );
}

const getAbsoluteUrl = (relativePath: string) => {
    const { queryString } = getQueryParamsAndQueryString();
    return ABSOLUTE_URL_PATTERN.replace('{protocol}', window.location.protocol)
        .replace('{host}', window.location.host)
        .replace('{urlParams}', queryString)
        .replace('{relativePath}', relativePath);
};

export function getHost(): string {
    const { queryString } = getQueryParamsAndQueryString();
    return `${window.location.origin}${queryString}`;
}

// TODO: Sreeni to take look at this for long term usage.
export function getSubDomainIfOrgEnabled(): string {
    if (!isOrgUiEnabled()) {
        return '';
    }
    const url = new URL(getUrlHref());
    const domains = url.hostname.split('.');
    if (domains[1] === getIdpDomain()) {
        return `${domains[0]}_`;
    }
    return '';
}

const emptyNavigate = () => {
    throw new Error('Nav service not initialized yet!');
};

export function getNavService(
    navigate: NavigateFunction = emptyNavigate,
    setNavPrompt?: (navPromptProps: { message: string; when: boolean }) => void,
    getLast401Path?: () => string,
): NavService {
    return {
        goToDataModelPage: () => {
            const path = generatePath(ROUTES.DataModelPage.path);
            navigate(path);
        },
        goToAdminPage: () => {
            const path = generatePath(ROUTES.AdminPage.path);
            navigate(path);
        },
        getCurrentPathByLocationHash: (includeQueryParams: boolean) => {
            let currentPath = window.location.hash;
            if (currentPath.startsWith('#')) {
                currentPath = currentPath.slice(1);
            }
            if (includeQueryParams) return currentPath;
            return currentPath.split('?')[0];
        },
        goToHome: () => {
            const path = generatePath(ROUTES.StratusHome.path);
            navigate(path);
        },
        goToAnswers: () => {
            const path = generatePath(ROUTES.Answers.path);
            navigate(path);
        },
        goToStratusHome: (urlQueryParams?: Array<QueryParams>) => {
            let path = generatePath(ROUTES.StratusHome.path);
            if (urlQueryParams) {
                path = addClientQueryParamsToPath(path, urlQueryParams);
            }
            navigate(path);
        },
        goToCopilot: (
            dataSourceId?: string,
            urlQueryParams?: Array<QueryParams>,
        ) => {
            let path = generatePath(ROUTES.Copilot.path);
            if (dataSourceId) {
                path = `${path}/${dataSourceId}`;
            }
            if (urlQueryParams) {
                path = addClientQueryParamsToPath(path, urlQueryParams);
            }
            navigate(path);
        },
        goToCopilotChat: (
            conversationId: string,
            urlQueryParams?: Array<QueryParams>,
        ) => {
            let path = generatePath(ROUTES.Copilot.path);
            path = `${path}/chat/${conversationId}`;
            if (urlQueryParams) {
                path = addClientQueryParamsToPath(path, urlQueryParams);
            }
            navigate(path);
        },
        goToUrl: (url: string, inNewTab?: boolean) => {
            if (inNewTab) {
                window.open(`${getHost()}/#${url}`, '_blank');
            } else {
                navigate(url);
            }
        },
        goToEurekaWithQueryParams: (urlQueryParams: Array<QueryParams>) => {
            let path = generatePath(
                ROUTES[getEmbedRoute('EurekaPage', isSageEmbed())].path,
            );
            if (urlQueryParams) {
                path = addClientQueryParamsToPath(path, urlQueryParams);
            }
            navigate(path);
        },
        goToCreateAiAnswerWithQueryParams: (
            urlQueryParams: Array<QueryParams>,
        ) => {
            let path = generatePath(
                ROUTES[getEmbedRoute('CreateAiAnswerPage', isSageEmbed())].path,
            );
            if (urlQueryParams) {
                path = addClientQueryParamsToPath(path, urlQueryParams);
            }
            navigate(path);
        },
        goToTrainSageWithQueryParams: (urlQueryParams?: Array<QueryParams>) => {
            let path = generatePath(ROUTES.TrainSagePage.path);
            if (urlQueryParams) {
                path = addClientQueryParamsToPath(path, urlQueryParams);
            }
            navigate(path);
        },
        goToAiAnswer: (
            eurekaAnswerSessionId: string,
            urlQueryParams?: Array<QueryParams>,
        ) => {
            let path = generatePath(ROUTES.AiAnswer.path, {
                eurekaAnswerSessionId,
            });
            if (urlQueryParams) {
                path = addClientQueryParamsToPath(path, urlQueryParams);
            }
            navigate(path);
        },
        goToMonitorV2: () => {
            const path = generatePath(ROUTES.MonitorV2.path);
            navigate(path);
        },
        goToSavedAnswer: (id: string, isEmbedded?: boolean) => {
            const path = generatePath(
                ROUTES[getEmbedRoute('SavedAnswer', isEmbedded)].path,
                {
                    answerId: id,
                },
            );
            navigate(path);
        },
        goToSavedAnswerWithClientFlags: (
            id: string,
            urlQueryParams?: Array<QueryParams>,
            isEmbedded?: boolean,
        ) => {
            let path = generatePath(
                ROUTES[getEmbedRoute('SavedAnswer', isEmbedded)].path,
                {
                    answerId: id,
                },
            );
            if (urlQueryParams) {
                path = addClientQueryParamsToPath(path, urlQueryParams);
            }
            navigate(path);
        },
        goToView: (id: string, urlQueryParams?: Array<QueryParams>) => {
            let path = generatePath(ROUTES.View.path, {
                answerId: id,
            });
            if (urlQueryParams) {
                path = addClientQueryParamsToPath(path, urlQueryParams);
            }
            navigate(path);
        },
        goToSavedSqlView: (id: string, urlQueryParams?: Array<QueryParams>) => {
            let path = generatePath(ROUTES.Tables.path, {
                sqlViewId: id,
            });
            if (urlQueryParams) {
                path = addClientQueryParamsToPath(path, urlQueryParams);
            }
            navigate(path);
        },
        getAbsoluteUrl: (path: string) => {
            return `${getHost()}/#${path}`;
        },
        goToEditACopy: (
            id: string,
            shouldOpenInNewTab: boolean,
            urlQueryParams?: Array<QueryParams>,
            isEmbedded?: boolean,
        ) => {
            let path = generatePath(
                ROUTES[getEmbedRoute('EditACopy', isEmbedded)].path,
                {
                    editACopySessionKey: id,
                },
            );
            if (urlQueryParams) {
                path = addClientQueryParamsToPath(path, urlQueryParams);
            }
            if (shouldOpenInNewTab) {
                const editACopyPath = `${getHost()}#${path}`;
                window.open(editACopyPath, '_blank');
                return;
            }
            navigate(path);
        },
        goToPinboard: (
            pinboardId: string,
            tabId: string,
            vizId: string,
            urlQueryParams?: Array<QueryParams>,
            replaceState = false,
            navigationState?: Record<string, string>,
        ) => {
            markEvent(userActionTypeEnum.REDIRECTED_TO_PINBOARD);
            let path = '';
            if (isLiveboardEmbed()) {
                if (tabId) {
                    path = generatePath(
                        ROUTES[getEmbedRoute('TabEmbed', true)].path,
                        {
                            pinboardId,
                            tabId,
                            vizId,
                        },
                    );
                } else {
                    path = generatePath(
                        ROUTES[getEmbedRoute('PinboardEmbed', true)].path,
                        {
                            pinboardId,
                        },
                    );
                }
            } else if (tabId) {
                path = generatePath(ROUTES.Tab.path, {
                    pinboardId,
                    tabId,
                    vizId,
                });
            } else {
                path = generatePath(ROUTES.Pinboard.path, {
                    pinboardId,
                    vizId,
                });
            }

            if (urlQueryParams) {
                path = addClientQueryParamsToPath(path, urlQueryParams);
            }

            navigate(path, {
                replace: replaceState && !isLiveboardEmbed(),
                ...(navigationState ? { state: navigationState } : {}),
            });
        },
        goToEmbraceOauthRedirection: () => {
            const path = generatePath(ROUTES.EmbraceOauth.path);
        },
        goToCustomCalendarRedirection: () => {
            const path = generatePath(ROUTES.CustomCalendarTest.path);
            navigate(path);
        },
        goToHomeAnswers: () => {
            navigate('/insights/home/answers');
        },
        goToDocuments: () => {
            navigate('/insights/doc-search');
        },
        goToHomeLiveboards: () => {
            const path = '/insights/home/liveboards';
            navigate(path);
        },
        goToHomeFavs: () => {
            navigate('/insights/home/favourites');
        },
        goToHomeLiveboardSchedules: () => {
            navigate('/insights/home/liveboard-schedules');
        },
        goToHomeCreatedByMe: () => {
            navigate('/insights/home/created-by-me');
        },
        goToHomeMonitorAlerts: () => {
            const path = '/insights/home/monitor-alerts';
            navigate(path);
        },
        goToHomeSpotIQAnalysis: () => {
            navigate('/insights/home/spotiq-analysis');
        },
        goToAnswer: (isEmbedded?: boolean) => {
            navigate(
                generatePath(ROUTES[getEmbedRoute('Answer', isEmbedded)].path),
            );
        },
        goToRouteKey: (routeKey, id, search) => {
            const path = generatePath(ROUTES[routeKey].path, {
                id,
            });
            navigate(path);
        },
        onPrompt: (message: string, when: () => boolean) => {
            setNavPrompt({ message, when: when() });
        },
        readSearchParams: (): URLSearchParams => {
            const [searchParams] = useSearchParams();
            return searchParams;
        },
        readClientQueryParamsFromPath: (): Map<
            string,
            string | boolean | number
        > => {
            const hash = getUrlHash();
            const clientSideQueryParams = new Map<
                string,
                string | boolean | number
            >();
            const params = hash.split('?').pop();
            params.split('&').forEach(param => {
                const flag = param.split('=');
                if (flag.length === 2) {
                    clientSideQueryParams.set(flag[0], flag[1]);
                }
            });
            return clientSideQueryParams;
        },
        isCurrentSearchPath: () => {
            const pathname = window.location.hash;
            return pathname.includes(ROUTES.Answer.path);
        },
        isCurrentEurekaPath: () => {
            const pathname = window.location.hash;
            // we get ROUTES.EurekaPage.path as '/insights?/eureka' and
            // pathname as '#/insights/eureka?query=' for eureka srp
            // and '#/embed/eureka?query=' for sage embed
            return (
                pathname.includes(ROUTES.EurekaPage.path.replace('?', '')) ||
                pathname.includes(
                    ROUTES[
                        getEmbedRoute('EurekaPage', isSageEmbed())
                    ].path.replace('insights?/', ''),
                ) // this is needed until we add navigation to eureka mfe
            );
        },
        isCurrentCreateAiAnswerPath: () => {
            const pathname = window.location.hash;
            // we get ROUTES.EurekaPage.path as '/insights?/eureka' and
            // pathname as '#/insights/eureka?query=' for eureka srp
            // and '#/embed/eureka?query=' for sage embed
            return (
                pathname.includes(
                    ROUTES.CreateAiAnswerPage?.path.replace('?', ''),
                ) ||
                pathname.includes(
                    ROUTES[
                        getEmbedRoute('CreateAiAnswerPage', isSageEmbed())
                    ]?.path.replace('insights?/', ''),
                ) // this is needed until we add navigation to eureka mfe
            );
        },
        isCurrentHomePath: () => {
            const pathname = window.location.hash;
            return pathname.includes(ROUTES.Home.path);
        },
        isCurrentStratusPath: () => {
            const pathname = window.location.hash;
            return pathname.includes(ROUTES.StratusHome.path);
        },
        isCurrentEurekaAiAnswerPath: () => {
            const pathname = window.location.hash;
            return pathname.includes(ROUTES.AiAnswer.path);
        },
        isCurrentPrintViewPath: () => {
            const pathname = window.location.hash;
            return pathname.includes(ROUTES.PrintView.path);
        },
        goToCustomCalendarWizard: () => {
            const path = generatePath(ROUTES.CustomCalendar.path);
            navigate(path);
        },
        goToSetup: () => {
            const path = generatePath(ROUTES.SetupHub.path);
            navigate(path);
        },
        goToActions: () => {
            const path = generatePath(ROUTES.EverywherePage.path);
            navigate(`${path}/actionsCustomization`);
        },
        goToOnBoarding: () => {
            navigate('/onboarding?isRevisiting=true');
        },
        goToAutoAnswer: (dataSourceId: string, flags: Array<QueryParams>) => {
            const path = generatePath(ROUTES.AutoAnswer.path, {
                dataSourceId,
            });
            const newPath = addClientQueryParamsToPath(path, flags);
            navigate(newPath);
        },
        goToRequestAccessForObject: (
            objectType: string,
            objectId: string,
            flags: Array<QueryParams>,
        ) => {
            const path = generatePath(ROUTES.RequestAccess.path, {
                objectType,
                objectId,
            });
            const newPath = addClientQueryParamsToPath(path, flags);
            navigate(newPath);
        },
        goToUserPreference: () => {
            const path = generatePath(ROUTES.UserPreferencePage.path);
            navigate(path);
        },
        openExternalUrlInNewTab: (externalLinkPath: string) => {
            const externalLinkUrl = `${externalLinkPath}`;
            window.open(externalLinkUrl, '_blank');
        },
        isCurrentSavedAnswerPath: () => {
            return getUrlHref().indexOf('/saved-answer') > -1;
        },
        isCurrentPinboardPath: () => {
            return getUrlHref().indexOf('/pinboard') > -1;
        },
        isEmbedded: () => {
            return getUrlHref().indexOf('/embed/') > -1;
        },
        goToLogin: () => {
            const path = generatePath(ROUTES.Login.path);
            navigate(path);
        },
        goToInsights: (tabId: string) => {
            const path = generatePath(ROUTES.SpotIQList.path);
            navigate(path);
        },
        goToInsight: (analysisResultId: string) => {
            const path = generatePath(ROUTES.SpotIQ.path, {
                analysisResultId,
            });
            navigate(path);
        },
        goToInsightInNewTab: (analysisResultId: string) => {
            const path = generatePath(ROUTES.SpotIQ.path, {
                analysisResultId,
            });
            window.open(`${getHost()}/#${path}`, '_blank');
        },
        goToTable: (id: string) => {
            const path = generatePath(`/data/tables/${id}`);
            navigate(path);
        },
        goToDataset: () => {
            const path = generatePath('/data/dataset');
            navigate(path);
        },
        goToDestinationSync: () => {
            const path = generatePath('/data/destination-sync');
            navigate(path);
        },
        goToSelectedDestinationSync: (id: string) => {
            const path = generatePath(`/data/destination-sync/${id}`);
            navigate(path);
        },
        goToUtilities: () => {
            const path = generatePath('/data/utilities');
            navigate(path);
        },
        goToDbt: () => {
            const path = generatePath('/data/dbt');
            navigate(path);
        },
        goToDestination: () => {
            const path = generatePath('/data/destination');
            navigate(path);
        },
        goToSqlView: () => {
            const path = generatePath('/data/sql-view');
            navigate(path);
        },
        goToSavedSqlViewPage: (id: string, flags: QueryParams[]) => {
            let path = generatePath('/data/sql-view');
            if (!_.isNil(id)) {
                path = generatePath('/data/sql-view/:id', {
                    id,
                });
            }
            navigate(path);
        },
        goToDataGovernance: () => {
            const path = generatePath('/data/data-governance');
            navigate(path);
        },
        goToLiveBoardVerification: () => {
            const path = generatePath('/data/liveboard-verification');
            navigate(path);
        },
        goToFragmentFeedback: () => {
            const path = generatePath('/data/fragment-feedback');
            navigate(path);
        },
        goToQueryFeedback: () => {
            const path = generatePath('/data/query-feedback');
            navigate(path);
        },
        goToAppConnections: () => {
            const path = generatePath('/data/app-connections');
            navigate(path);
        },
        goToDestinationType: (type: string) => {
            const path = generatePath(
                `/data/destination-sync/auth/type/${type}`,
            );
            navigate(path);
        },
        goToDestinationAuthComplete: () => {
            const path = generatePath('/data/destination-sync/auth/complete');
            navigate(path);
        },
        goToTeamsMembers: () => {
            const path = generatePath('/teams/members');
            navigate(path);
        },
        goToTeamsPendingInvitations: () => {
            const path = generatePath('/teams/pending-invitations');
            navigate(path);
        },
        goToTeamsPlanOptions: () => {
            const path = generatePath('/teams/plan-options');
            navigate(path);
        },
        goToTeamsSpotIQOptions: () => {
            const path = generatePath('/teams/spotiq');
            navigate(path);
        },
        goToTeamsStyleCustomizationOptions: () => {
            const path = generatePath('/teams/style-customization');
            navigate(path);
        },
        goToTeamsUserManagementOptions: () => {
            const path = generatePath('/teams/user-management');
            navigate(path);
        },
        goToTeamsAuthenticationOptions: () => {
            const path = generatePath('/teams/authentication');
            navigate(path);
        },
        goToTeamsSystemActivitiesOptions: () => {
            const path = generatePath('/teams/system-activities');
            navigate(path);
        },
        goToTeamsDataUsage: () => {
            const path = generatePath('/teams/data-usage');
            navigate(path);
        },
        goToTeamsManageSubscription: () => {
            const path = generatePath('/teams/manage-subscription');
            navigate(path);
        },
        goToManageSchedules: (pinboardId: string) => {
            const path = generatePath(`/schedules/${pinboardId}`);
            navigate(path);
        },
        goToOrgsHome: () => {
            const path = generatePath('/orgs');
            navigate(path);
        },
        goToOrgsUsers: () => {
            const path = generatePath('/orgs/users');
            navigate(path);
        },
        goToOrgsGroups: () => {
            const path = generatePath('/orgs/groups');
            navigate(path);
        },
        goToOrgsContent: () => {
            const path = generatePath('/orgs/content');
            navigate(path);
        },
        goToOrgsCreditConsumption: () => {
            const path = generatePath('/orgs/credit-consumption');
            navigate(path);
        },
        goToResetPassword: () => {
            const path = generatePath(ROUTES.ResetPassword.path);
            navigate(path);
        },
        goToForgotPassword: () => {
            const path = generatePath(ROUTES.ForgotPassword.path);
            navigate(path);
        },
        goToSearchAssist: () => {
            const path = generatePath(ROUTES.SearchAssist.path);
            navigate(path);
        },
        getUrlHash() {
            return window.location.hash;
        },
        getHistoryState: () => {
            // History state accessible via useLocation hook can also be accessed as follows
            // Reference: https://github.com/remix-run/react-router/blob/main/packages/router/history.ts#L190
            return window.history?.state?.usr || {};
        },
        goToDeepLinkPage: () => {
            const path = generatePath(ROUTES.Deeplink.path);
            const redirectUri = getUrlHash();
            const queryParams = [
                {
                    name: 'redirectURi',
                    value: redirectUri,
                },
            ];
            const newPath = addClientQueryParamsToPath(path, queryParams);
            navigate(newPath);
        },
        goToWorksheetCreate: (id: string, flags: QueryParams[]) => {
            let path = generatePath('/worksheet/create');
            path = addClientQueryParamsToPath(path, flags);
            navigate(path);
        },
        goToAutoWorksheetWithConnectionId: (
            connectionId?: string,
            origin?: string,
        ) => {
            let path = generatePath('/data/worksheet/create/auto');
            if (connectionId) {
                path = addClientQueryParamsToPath(path, [
                    {
                        name: 'connectionId',
                        value: connectionId,
                    },
                    {
                        name: 'origin',
                        value: origin,
                    },
                ]);
            }
            navigate(path);
        },
        goToFalconCsvUpload: (origin: string, tableId?: string) => {
            let path = generatePath('/data/importcsv');
            const urlQueryParams: QueryParams[] = [
                { name: 'isFalcon', value: 'true' },
            ];
            if (tableId) {
                urlQueryParams.push({
                    name: 'tableId',
                    value: tableId,
                });
            }
            if (origin) {
                urlQueryParams.push({ name: 'origin', value: origin });
            }
            path = addClientQueryParamsToPath(path, urlQueryParams);
            navigate(path);
        },
        goToEmbrace: (id: string, flags: QueryParams[]) => {
            let path = generatePath('/data/embrace');
            if (!_.isNil(id)) {
                path = generatePath('/data/embrace/:id', {
                    id,
                });
            }
            path = addClientQueryParamsToPath(path, flags);
            navigate(path);
        },
        goToEmbraceConnections: (flags: QueryParams[]) => {
            let path = generatePath('/data/embrace/connection');
            path = addClientQueryParamsToPath(path, flags);
            navigate(path);
        },
        goToEmbraceConnectionEdit: (connectionId: string) => {
            const path = generatePath('/data/embrace/:connectionId/edit', {
                connectionId,
            });
            navigate(path);
        },
        goToEmbraceConnectionEditWithFlags: (
            connectionId: string,
            flags: QueryParams[],
        ) => {
            let path = generatePath('/data/embrace/:connectionId/edit', {
                connectionId,
            });
            path = addClientQueryParamsToPath(path, flags);
            navigate(path);
        },
        reload() {
            window.location.reload();
        },
        shouldShowDeepLinkPage() {
            return (
                isPlatformDeeplinkSupported() &&
                !doesUserWantBrowserExperience() &&
                !isDeepLinkingDisabled() &&
                !isEmbeddedApp()
            );
        },
        canShowNavBar(
            isAppEmbedded: boolean,
            primaryNavHidden: boolean,
            isNonEmbedFullAppAccessBlocked: boolean,
        ): boolean {
            if (
                (primaryNavHidden && isAppEmbedded) ||
                (!isAppEmbedded && isNonEmbedFullAppAccessBlocked)
            ) {
                return false;
            }
            return true;
        },
        goToSamlLogin: () => {
            const last401Path = getLast401Path();
            if (!last401Path) {
                window.open('/callosum/v1/saml/login', '_self');
                return;
            }
            const targetURL = encodeURIComponent(getAbsoluteUrl(last401Path));
            window.open(
                `/callosum/v1/saml/login?targetURLPath=${targetURL}`,
                '_self',
            );
        },
        goToOidcLogin: (oidcLoginUri, oidcIssuerURI, urlQueryPathForError) => {
            const last401Path = getLast401Path();
            const targetURL = encodeURIComponent(getAbsoluteUrl(last401Path));
            if (last401Path) {
                window.open(
                    `${oidcLoginUri}?targetURLPath=${targetURL}`,
                    '_self',
                );
            } else if (urlQueryPathForError) {
                try {
                    const url = new URL(oidcIssuerURI);
                    window.open(
                        `${url.origin}/signin/?${urlQueryPathForError}`,
                    );
                } catch (error) {
                    window.open(oidcLoginUri, '_self');
                }
            } else {
                let path = oidcLoginUri;
                if (flags.getValue(FLAGS.disableSAMLAutoRedirect.name)) {
                    path = `${path}?${FLAGS.disableSAMLAutoRedirect.name}=true`;
                }
                if (flags.getValue(FLAGS.forceSAMLAutoRedirect.name)) {
                    if (path.indexOf('?') >= 0) {
                        path = `${path}&${FLAGS.forceSAMLAutoRedirect.name}=true`;
                    } else {
                        path = `${path}?${FLAGS.forceSAMLAutoRedirect.name}=true`;
                    }
                }
                window.open(path, '_self');
            }
        },
        goToPurchase: () => {
            const path = generatePath('/purchase');
            navigate(path);
        },
        // To be implemented
        goToImportTsl: importType => {
            const path = generatePath(CORE_ROUTES.ImportTsl.path, {
                importType,
            });
            navigate(path);
        },
        goToPinboards: _.noop,
        goToSpotApp: _.noop,
        goToSpotAppExport: _.noop,
        goToTables: _.noop,
        goToTslEditorSingleFile: (metadataType, metadataGuid) => {
            const path = generatePath(CORE_ROUTES.TmlEditor.path, {
                metadataType,
                metadataGuid,
            });
            navigate(path);
        },
        goToUpdateTslFromFile: (metadataType, metadataGuid) => {
            const path = generatePath(CORE_ROUTES.ImportTml.path, {
                metadataType,
                metadataGuid,
            });
            navigate(path);
        },
        openSearchAssistForWorksheet: _.noop,
        toggleUserMenuPopup: _.noop,
        goToSpotAppsAdmin: _.noop,
        goToSpotAppDetails: _.noop,
        goToDbtIntegration: _.noop,
        goToSpotAppsAnalytics: _.noop,
        goToAdmin: _.noop,
        adminTabSwitcher: {
            registerCb: _.noop,
            deregisterCb: () => _.noop,
        },
        goToDemo: _.noop,
        getSubDomainIfOrgEnabled: () => {
            return getSubDomainIfOrgEnabled();
        },
        goToDevelopTab: () => {
            const path = generatePath('/develop');
            navigate(path);
        },
        goToWorkSheetV2: (queryParams?: QueryParams[]) => {
            let path = '/worksheet/create';
            if (queryParams) {
                path = addClientQueryParamsToPath(path, queryParams);
            }
            navigate(path);
        },
        goToSetupWithFlags: (queryParams?: QueryParams[]) => {
            let path = '/setup';
            if (queryParams) {
                path = addClientQueryParamsToPath(path, queryParams);
            }
            navigate(path);
        },
        isInitialized: navigate !== emptyNavigate,
    };
}

const useCustomNavigateFunction = () => {
    const routerNavigate = useRouterNavigate();
    const matches = useMatches();
    return (to: To | number, options: NavigateOptions = {}) => {
        if (typeof to === 'number') {
            return routerNavigate(to);
        }
        const targetPath = typeof to === 'string' ? to : to.pathname;
        const currentAppMatch = matches.find(
            match => (match.handle as any)?.handle,
        );
        const currentApp =
            currentAppMatch &&
            currentAppMatch.id !== ApplicationList[Applications.CORE].id &&
            ApplicationList[currentAppMatch.id];
        if (
            !currentApp ||
            currentApp === Applications.CORE ||
            Object.values(currentApp.children).some(route => {
                const pathMatch = matchPath(
                    (route as PrivilegedRouteObject)?.path || '',
                    targetPath,
                );
                return pathMatch && !ApplicationList[pathMatch.params['*']];
            })
        ) {
            return routerNavigate(to, options);
        }
        // eslint-disable-next-line security/detect-non-literal-fs-filename
        return window.open(`#${targetPath}`, '_self');
    };
};

export function useSetupNavService() {
    const [promptProps, setPromptProps] = useState<{
        message: string;
        when: boolean;
    }>({
        message: '',
        when: false,
    });
    usePrompt(promptProps);
    const { setNavService, sessionService } = useAppContext();
    const navigate = useCustomNavigateFunction();
    return () => {
        const navService = getNavService(
            navigate,
            setPromptProps,
            sessionService.getLast401Path,
        );
        sessionService.getOnUserLoggedOut().subscribe(() => {
            navService.goToLogin();
        });
        setNavService(navService);
    };
}

export function getUrlPath(): string {
    return window.location.pathname;
}

export function getCurrentPage() {
    // TODO
}

export function getPreviousPage() {
    // TODO
}

export function reloadApplication() {
    window.location.reload();
}

export function getCurrentPathByLocationHash(
    includeQueryParams = true,
): string {
    let currentPath = window.location.hash;
    if (currentPath.startsWith('#')) {
        currentPath = currentPath.slice(1);
    }
    if (includeQueryParams) return currentPath;
    return currentPath.split('?')[0];
}

export function removeOverrideOrgId(url: string): string {
    let currentUrl = url;
    if (currentUrl.includes(`${FLAGS.overrideOrgId.name}=`)) {
        const [baseUrl, hash] = currentUrl.split('#');
        const [basePath, queryString] = baseUrl.split('?');

        const queryStringWithoutOrgOverride = queryString.replace(
            /(?:&|^)orgId=[^&]*/,
            '',
        );

        currentUrl = `${basePath}${
            queryStringWithoutOrgOverride
                ? `?${queryStringWithoutOrgOverride}`
                : ''
        }${hash ? `#${hash}` : ''}`;
        return currentUrl;
    }
    return currentUrl;
}

export function includeCurrentOrgIdIfOrgEnabled(url: string): string {
    if (!isOrgPerUrlEnabled() || getSubDomainIfOrgEnabled()) {
        return url;
    }

    if (url.includes('/admin')) {
        return removeOverrideOrgId(url);
    }

    const DELIMITER = '#';
    const splittedUrl = url.split(DELIMITER);

    let currentOrgId = getCurrentOrgId();

    const overrideOrgId = flags.getValue(FLAGS.overrideOrgId.name);

    if (overrideOrgId === 0 || overrideOrgId) {
        currentOrgId = overrideOrgId;
    }

    if (url.includes(`${FLAGS.overrideOrgId.name}=`)) {
        splittedUrl[0] = url.replace(
            new RegExp(`${FLAGS.overrideOrgId.name}=[^&]*`),
            `${FLAGS.overrideOrgId.name}=${currentOrgId}`,
        );
    } else {
        const orgIdParam = `${FLAGS.overrideOrgId.name}=${currentOrgId}`;
        splittedUrl[0] += splittedUrl[0].includes('?')
            ? `&${orgIdParam}`
            : `?${orgIdParam}`;
    }

    return splittedUrl.join(DELIMITER);
}

export function isCurrentPrintViewPath() {
    const pathname = window.location.hash;
    return pathname.includes(ROUTES.PrintView.path);
}

export const getUrlWithoutUTMParameters = () => {
    const fullUrl = window.location.href;
    const [baseUrl, hash] = fullUrl?.split('#');
    const url = new URL(baseUrl);
    const urlParams = new URLSearchParams(url.search);
    urlParams.delete(FLAGS.utm_source.name);
    urlParams.delete(FLAGS.utm_medium.name);
    url.search = urlParams?.toString();
    const newUrl = url?.toString() + (hash ? `#${hash}` : '');
    return newUrl;
};

export const replaceState = (url: string) => {
    window.history.replaceState(null, '', url);
};
